@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';

.case-details {
  $breakpoint: 800px;

  &__back {
    margin-bottom: spacing.$l;
  }

  &__header {
    @media (min-width: $breakpoint) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__header-end {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: $breakpoint) {
      align-items: flex-end;
    }
  }

  &__header-tag {
    margin-bottom: spacing.$s;
  }

  &__header-date {
    align-self: flex-end;
  }

  &__heading {
    margin-bottom: spacing.$xs;
  }

  &__sub-heading {
    margin-bottom: spacing.$s;
    @media (min-width: $breakpoint) {
      margin-bottom: spacing.$l;
    }
  }

  &__details {
    width: 100%;
    border-radius: corner-radius.$m;
    padding: spacing.$l;
    color: light.$on-surface-primary-default;
    background-color: light.$surface-primary-default;
    margin: spacing.$l 0;
  }

  &__details-row {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
    margin: 0;

    &:not(:last-of-type) {
      margin-bottom: spacing.$xs;
      @media (min-width: $breakpoint) {
        margin-bottom: spacing.$l;
      }
    }

    @media (min-width: $breakpoint) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__detail {
    max-width: 440px;

    &:not(:last-child) {
      margin-right: spacing.$xxl;
    }
  }

  &__detail-description {
    margin: 0;
  }

  &__comments-title {
    margin-top: spacing.$l;
    margin-bottom: spacing.$s;
  }

  &__comments {
    max-height: 562px;
    overflow-y: auto;
    width: 100%;
    border-radius: corner-radius.$m corner-radius.$m 0 0;
    padding: spacing.$m;
    color: light.$on-surface-primary-default;
    background-color: light.$surface-primary-default;

    @media (min-width: $breakpoint) {
      padding: spacing.$xl;
    }
  }

  &__comments-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__comment-empty {
    color: light.$on-background-primary-alternate;
  }

  &__comment-wrapper {
    display: flex;
    align-items: flex-end;
    gap: spacing.$s;

    &:not(:last-child) {
      margin-bottom: spacing.$xl;
    }

    &--is-you {
      flex-direction: row-reverse;
    }
  }

  &__comment {
    display: grid;
    gap: spacing.$m;
    background-color: light.$surface-secondary-default;
    max-width: 409px;
    width: 100%;
    padding: spacing.$m;
    border-radius: corner-radius.$l corner-radius.$l corner-radius.$l 4px;
    margin-right: 40px;

    &--is-you {
      background-color: light.$signal-surface-information;
    }

    &--is-member-care {
      background-color: light.$signal-surface-featured;
    }

    @media (min-width: $breakpoint) {
      margin-right: 0;
    }
  }

  &__comment-info {
    display: flex;
    justify-content: space-between;
    color: light.$on-surface-primary-alternate;
  }

  &__comment-icon {
    margin-bottom: spacing.$s;
  }

  &__circle {
    width: 24px;
    height: 24px;

    @media (min-width: $breakpoint) {
      width: 42px;
      height: 42px;
    }

    border-radius: 50%;
    background-color: light.$fixed-surface-primary-default;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    flex-shrink: 0;
  }

  &__comment-form {
    display: flex;
    border-radius: 0px 0px corner-radius.$s corner-radius.$s;
  }

  &__comments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
